<template>
  <div>
    <div
      v-if="isLoading"
      id="loading-bg"
    >
      <div class="loading-logo">
        <p>Please Wait</p>
      </div>
      <div class="loading">
        <div class="effect-1 effects" />
        <div class="effect-2 effects" />
        <div class="effect-3 effects" />
      </div>
    </div>

    <section
      v-else
      id="dashboard-ecommerce"
    >
      <b-row class="match-height">
        <b-col
          xl="4"
          md="6"
        >
          <ecommerce-medal :data="data.congratulations" />
        </b-col>
      </b-row>
    </section>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

import { getUserData } from '@/auth/utils'

// import router from '@/router'
import EcommerceMedal from './EcommerceMedal.vue'

export default {
  components: {
    BRow,
    BCol,
    EcommerceMedal,
  },
  data() {
    return {
      data: {},
      isLoading: false,
    }
  },

  created() {
    this.$http.get('/ecommerce/data').then(response => {
      this.data = response.data
      const userData = getUserData()
      this.data.congratulations.name = `${userData.first_name} ${userData.last_name}`
    })
  },
  methods: {
  },
}
</script>

// <style lang="scss">
// @import "@core/scss/vue/pages/dashboard-ecommerce.scss";
// @import "@core/scss/vue/libs/chart-apex.scss";
// </style>
